import { render, staticRenderFns } from "./ImagePreview.vue?vue&type=template&id=2b1b7a1d&scoped=true&"
import script from "./ImagePreview.vue?vue&type=script&lang=js&"
export * from "./ImagePreview.vue?vue&type=script&lang=js&"
import style0 from "./ImagePreview.vue?vue&type=style&index=0&id=2b1b7a1d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b1b7a1d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';
installComponents(component, {VChip,VImg,VListItem,VVirtualScroll})


/* vuetify-loader */
import installDirectives from "!../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Intersect from 'vuetify/lib/directives/intersect'
installDirectives(component, {Intersect})
