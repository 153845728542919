<template>
  <v-menu
    :value="open"
    :close-on-click="false"
    :nudge-right="20"
    transition="slide-x-transition"
    bottom
    right
    offset-x
    allow-overflow
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        icon
      >
        <v-icon>mdi-help-circle-outline</v-icon>
      </v-btn>
    </template>

    <v-card max-width="400" max-height="860">
      <v-card-title>{{ $t('iconography') }}</v-card-title>

      <v-list two-line>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
        >
          <v-list-item-icon>
            <v-icon 
              :disabled="item.disabled"
              :color="item.color || 'defaultIconColor'"
              v-text="item.icon"
            ></v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-html="$t(item.title)"></v-list-item-title>
            <v-list-item-subtitle v-html="$t(item.description)" class="description-text"></v-list-item-subtitle>
          </v-list-item-content>

        </v-list-item>
      </v-list>

    </v-card>
  </v-menu>
</template>

<style scoped>
  .description-text{
    white-space: unset !important;
  }
</style>

<script>

export default {
  name: 'ClassificationHelper',

  props: {
    open: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    items: [
      {
        icon: 'mdi-checkbox-blank-outline',
        title: 'no_mark_selected_title',
        disabled: false,
        description: 'no_mark_selected_description'
      },
      {
        icon: 'mdi-checkbox-blank-outline',
        title: 'no_mark_selected_disabled_title',
        disabled: true,
        description: 'no_mark_selected_disabled_description'
      },
      {
        icon: 'mdi-checkbox-marked',
        title: 'mark_selected_title',
        description: 'mark_selected_description',
        disabled: false,
        color: "primary"
      },
      {
        icon: 'mdi-checkbox-marked',
        title: 'mark_selected_disabled_title',
        disabled: true,
        description: 'mark_selected_disabled_description'
      },
      {
        icon: 'mdi-checkbox-marked',
        title: 'mark_selected_inherited_disabled_title',
        disabled: true,
        description: 'mark_selected_inherited_disabled_description'
      },
      {
        icon: '$checkbox_eye',
        title: 'mark_higher_clearance_title',
        description: 'mark_higher_clearance_description',
        disabled: false,
        color: "primary",
      },
      {
        icon: '$checkbox_eye',
        title: 'mark_higher_clearance_disabled_title',
        description: 'mark_higher_clearance_disabled_description',
        disabled: true,
      },
      {
        icon: '$checkbox_eye_off',
        title: 'mark_selected_inherited_overwritten_title',
        description: 'mark_selected_inherited_overwritten_description',
        disabled: true,
      },
    ]
  }),
}

</script>
