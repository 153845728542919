var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"image-preview-container"},[(_vm.previewsUrls.length === 1)?_c('v-img',{attrs:{"src":_vm.singlePagePreviewUrl,"max-height":"900","max-width":"900","contain":""}}):_c('v-virtual-scroll',{staticClass:"d-flex justify-center",attrs:{"items":_vm.previewsUrls,"bench":_vm.previewsUrls.length,"item-height":900,"max-height":"900"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-list-item',{directives:[{name:"intersect",rawName:"v-intersect.quiet",value:({
          handler: _vm.onIntersect,
          options: {
            threshold: [0.75]
          }
        }),expression:"{\n          handler: onIntersect,\n          options: {\n            threshold: [0.75]\n          }\n        }",modifiers:{"quiet":true}}],attrs:{"id":index}},[_c('v-img',{attrs:{"src":item,"max-height":"900","max-width":"900","contain":"","eager":""}})],1)]}}])}),(_vm.previewsUrls.length > 1)?_c('v-chip',{staticClass:"page-indicator",attrs:{"label":"","dark":""}},[_vm._v(" "+_vm._s(_vm.$t('page'))+" "+_vm._s(_vm.currentPage)+"/"+_vm._s(_vm.previewsUrls.length)+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }