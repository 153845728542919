<template>
  <div>

    <div class="v-input v-input--is-label-active v-input--is-dirty v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined">
      <div class="v-input__control">
        <div class="v-input__slot">
          <fieldset aria-hidden="true">
            <legend style="width: 112px;">
              <span></span>
            </legend>
          </fieldset>
          <div class="v-text-field__slot" style="width:100%">
            <label class="v-label v-label--active theme--light" style="left: 0px; margin-top: -7px; right: auto; position: absolute;">
              {{ label }}
            </label>

            <div style="width:100%; padding:10px 10px 20px 10px" fill-height fluid>
              <v-row align="center" justify="center">
                <v-progress-circular
                  :size="28"
                  v-if="loadingEntry"
                  color="primary"
                  indeterminate
                ></v-progress-circular>

                <v-icon
                  style="margin-top:20px; margin-bottom:10px"
                  color="grey-darken1"
                  :size="86"
                  v-else-if="!this.nodesSelected"
                >{{ icon }}</v-icon>

                <div class="col" v-else>
                  <v-img
                    class="white--text align-center img-bg custom-thumb"
                    width="100%"
                    v-if="thumbUrl"
                    :src="thumbUrl"
                    align="center"
                    justify="center"
                    contain
                  ></v-img>

                  <v-icon
                    v-else
                    :color="thumbIcon.color || 'defaultIconColor'"
                    size="36"
                  >{{ thumbIcon.icon }}</v-icon>
                </div>
              </v-row>

              <div class="row" :style="nodesSelected ? 'height:32px' : ''">
                <div class="text" v-if="loadingEntry">
                  {{ $t('Loading...') }}
                </div>

                <div
                  class="col text-center"
                  v-else-if="!nodesSelected"
                >
                  <a @click="selectFile()">
                    {{ $t(clickText) }}
                  </a>
                    {{ $t(selectText) }}
                </div>

                <v-row v-if="nodesSelected" class="ma-0 filename-container">
                  <v-col cols="10" class="pa-1">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          v-bind="attrs"
                          v-on="on"
                          class="filename-text-wraper"
                        >{{ selectedNodes[0].name }}</div>
                      </template>
                      <span>{{ selectedNodes[0].name }}</span>
                    </v-tooltip>
                  </v-col>

                  <v-col cols="2" class="d-flex pa-0 justify-center">
                    <v-btn
                      @click="resetSelectedNodes"
                      icon
                    >
                      <v-icon>mdi-close-circle-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-dialog
      class="navModal"
      v-model="navModal"
      width="70%"
      scrollable
    >
      <v-card class="navContainer">
        <v-card-title>
          {{ $t(modalTitle) }}
        </v-card-title>

        <v-card-text style="height: 500px">
          <Breadcrumbs
            :items="formattedBreadcrumbs"
            maxWidthItem="25%"
            class="breadcrumb"
            @breadcrumbNav="breadcrumbNav"
            v-if="parentNodeId && !loadingEntries"
          />

          <v-row
            class="fill-height"
            align-content="center"
            justify="center"
            v-if="!parentNodeId"
          >
            <v-col
              class="text-subtitle-1 text-center"
              cols="12"
            >
              <span>{{ $t('Parent NodeID not found.') }}</span>
            </v-col>
          </v-row>

          <v-row
            class="fill-height"
            align-content="center"
            justify="center"
            v-if="parentNodeId && loadingEntries"
          >
            <v-col
              class="text-subtitle-1 text-center"
              cols="12"
            >
              <span v-if="loadingEntries">{{ $t('retrieving_files') }}</span>

              <span v-else>{{ $t('empty_folder') }}</span>
            </v-col>

            <v-col
              v-if="loadingEntries"
              cols="6"
            >
              <v-progress-linear
                color="primary"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <v-row v-if="folders.length > 0 && !loadingEntries">
            <v-col
              class="py-2 px-2"
              v-for="item in folders"
              :key="item.node_id"
              xl="2"
              lg="3"
              md="4"
              sm="6"
              cols="12"
            >
              <FolderCard
                dense
                :data="item"
                @click-folder="navigate(item.node_id)"
              />
            </v-col>
          </v-row>
          <v-row v-if="files.length > 0 && !loadingEntries">
            <v-col
              v-for="item in files"
              :key="item.node_id"
              class="d-flex child-flex py-2 px-2"
              xl="2"
              lg="3"
              md="4"
              sm="6"
              cols="12"
            >
              <FileCard
                :data="item"
                disable-actions-menu
                @toggle-selection="toggleSelection"
                :ref="'fileCardRef' + item.node_id"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="navModal = false">{{ $t('cancel') }}</v-btn>
          <v-btn text @click="dispatchSelectNodes" color="primary">{{ $t('select') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
  .component {
    cursor: pointer;
    padding: 0;
    margin-bottom: 20px;
    height: 68px;
  }

  .navContainer h2 {
    margin-bottom: 10px;
    color: var(--v-secondary-darken1);
    font-weight: 500;
  }

  .breadcrumb {
    margin-bottom: 20px;
  }

  .img-bg {
    background-color: #DADCE0;
  }

  .filename-container {
    width: 100% !important;
  }

  .filename-text-wraper {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
</style>

<script>

import _ from 'lodash'
import { mapActions } from 'vuex'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import FolderCard from '@/components/content/FolderCard.vue'
import FileCard from '@/components/content/FileCard.vue'
import EntryActions from '@/store/content/entry/actions-types'
import IconsByMimetype from '@/utils/iconsByMimetype.js'

export default {
  name: 'SelectFileField',

  components: {
    Breadcrumbs,
    FolderCard,
    FileCard
  },

  props: {
    label: {
      type: String
    },

    icon: {
      type: String,
      default: 'mdi-file-outline'
    },

    iconColor: {
      type: String,
      default: 'defaultIconColor'
    },

    accepts: {
      type: String
    },

    clickText: {
      type: String,
      default: 'Click here'
    },

    selectText: {
      type: String,
      default: 'to select a file'
    },

    modalTitle: {
      type: String,
      default: 'Select a file'
    },

    parentNodeId: {
      type: Number
    },

    multipleSelect: {
      type: Boolean,
      default: false
    },

    value: {
      type: [Array, Number, String]
    }
  },

  mounted() {
    if (this.value && !this.nodesSelected) {
      this.retrieveEntryData()
    }
  },

  watch: {
    value(val) {
      if (!val) {
        this.resetSelectedNodes()
        return
      }

      this.retrieveEntryData()
    }
  },

  data: function() {
    return {
      navModal: false,
      loadingEntries: false,
      breadcrumb: [],
      entries: [],
      selectedNodesIds: [],
      selectedNodes: [],
      nodesSelected: false,
      loadingEntry: false
    }
  },

  computed: {
    formattedBreadcrumbs() {
      let first = {
        prependIcon: 'mdi-home-outline',
        iconColor: 'secondary',
        disabled: false,
        ripple: false,
        tooltipText: "Home",
        nodeId: this.parentNodeId
      }

      if (!this.breadcrumb || this.breadcrumb.length < 2) {
        return [first]
      }

      first['link'] = true
      first['iconColor'] = 'primary'
      first['event'] = 'breadcrumbNav'

      const formatted = []
      formatted.push(first)

      const reversed = [].concat(this.breadcrumb).reverse()
      reversed.shift()

      const last = reversed.pop()

      reversed.forEach(element => {
        formatted.push({
          event: 'breadcrumbNav',
          text: element.name,
          ripple: false,
          nodeId: element.node_id,
          color: 'primary'
        })
      })

      formatted.push({
        text: last.name,
        ripple: false
      })

      return formatted
    },

    folders() {
      return this.orderItems(this.entries.filter(e => e.type == "folder" && !  /^\..*/.test(e.name)))
    },

    files() {
      let files = this.orderItems(this.entries.filter(e => e.type == "file" && ! /^\..*/.test(e.name)))

      return files
    },

    thumbIcon() {
      return IconsByMimetype.getIconByMimetype(this.selectedNodes[0].extension, this.selectedNodes[0].mime)
    },

    thumbUrl() {
      if (this.selectedNodes.length == 0) {
        return false
      }

      if (Array.isArray(this.selectedNodes[0].renditions)) {
        return this.selectedNodes[0].renditions[0]
      }

      if (typeof this.selectedNodes[0].renditions === 'object') {
        return Object.values(this.selectedNodes[0].renditions)[0]
      }

      return false;
    }
  },

  methods: {
    ...mapActions('content/entry', [
      EntryActions.GET_ENTRY_DATA,
      EntryActions.LIST_SUBFOLDER
    ]),

    selectFile() {
      this.navModal = true

      if (!this.parentNodeId) {
        return
      }

      this.navigate(this.parentNodeId)
    },

    toggleSelection(nodeId) {
      let selectedNodesIds = this.selectedNodesIds
      const idx = selectedNodesIds.indexOf(nodeId)

      if (idx > -1) {
        selectedNodesIds.splice(idx, 1)
      } else {
        if (this.multipleSelect) {
          selectedNodesIds.push(nodeId)
        } else {
          selectedNodesIds = [nodeId]
        }
      }

      this.selectedNodesIds = selectedNodesIds
      this.toggleSelected()
    },

    navigate(parentId) {
      this.selectedNodes = []
      this.selectedNodesIds = []
      this.loadingEntries = true

      this[EntryActions.LIST_SUBFOLDER]({parentId})
        .then((data) => {
          this.loadingEntries = false
          this.breadcrumb = data.breadcrumb
          this.entries = data.entries.filter(entry => {
            if (entry.type != 'file' || !this.accepts) {
              return true
            }

            return entry.mime.toLowerCase().match(this.accepts.toLowerCase()) !== null
          })
          this.toggleSelected()
        })
    },

    orderItems(items) {
      const notNullFilters = _.pickBy(this.orderBy)

      if (_.isEmpty(notNullFilters)) {
        return items
      }

      return _.orderBy(items, _.keys(notNullFilters), _.values(notNullFilters))
    },

    breadcrumbNav(item) {
      this.navigate(item.nodeId)
    },

    toggleSelected() {
      for (const prop in this.$refs) {
        if (prop.substring(0, 11) == 'fileCardRef') {
          const nodeId = parseInt(prop.substring(11))
          if (this.selectedNodesIds.indexOf(nodeId) > -1 && this.$refs[prop][0]) {
            this.$refs[prop][0].markSelectionAs(true)
            continue
          }

          if (this.$refs[prop][0]) {
            this.$refs[prop][0].markSelectionAs(false)
          }
        }
      }
    },

    dispatchSelectNodes() {
      const selectedNodes = this.entries.filter(entry => this.selectedNodesIds.indexOf(entry.node_id) > -1)

      this.selectedNodes = selectedNodes
      this.$emit('selectNodes', selectedNodes)

      this.navModal = false

      this.markSelectedNodesIds()
    },

    markSelectedNodesIds() {
      if (this.selectedNodesIds.length > 0) {
        this.nodesSelected = true
      }
    },

    resetSelectedNodes() {
      this.selectedNodes = []
      this.selectedNodesIds = []
      this.nodesSelected = false
      this.dispatchSelectNodes()
    },

    retrieveEntryData() {
      if (this.nodesSelected) {
        return
      }

      if (Array.isArray(this.value)) {
        this.value.forEach((nodeId) => {
          this.getAndPushNodeById(nodeId)
        })

        return
      }

      this.getAndPushNodeById(this.value)
    },

    getAndPushNodeById(nodeId) {
      this.loadingEntry = true
      this[EntryActions.GET_ENTRY_DATA](nodeId)
        .then((data) => {
          this.selectedNodes.push(data)
          this.selectedNodesIds.push(nodeId)
          this.markSelectedNodesIds()
          this.loadingEntry = false
        })
    }
  }
}

</script>