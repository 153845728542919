<template>
  <DefaultModal
    :title="$t('share_network_drive')"
    ref="modal"
    @submit="save"
    max-width="502px"
  >
    <template v-slot:content>

      <v-text-field
        :label="$t('network_drive_name')"
        v-model="networkDriveName"
        :rules="rules"
        :disabled="networkDrive && networkDrive.title ? true : false"
        autofocus
        outlined
        dense
      ></v-text-field>

      <v-autocomplete
        v-model="selectedUsersAndGroups"
        :items="normalizedUsersAndGroups"
        :label="$t('add_users_and_groups')"
        :rules="rules"
        :loading="loading"
        item-text="name"
        item-value="id"
        @blur="fetchSelectedUsersAndGroups"
        @keydown.esc="fetchSelectedUsersAndGroups"
        @keydown.enter="fetchSelectedUsersAndGroups"
        outlined
        multiple
        dense
      >
        <template v-slot:selection>
        </template>

        <template v-slot:item="data">
          <template>
            <v-list-item-avatar class="list-avatar" :class="data.item.isGroup ? 'group-bg' : 'avatar'">
              <img
                v-if="data.item.avatar_url"
                :src="data.item.avatar_url"
              >

              <v-icon color="secondary" v-else-if="data.item.isGroup"
              >mdi-account-multiple</v-icon>

              <span v-else class="secondary--text text-uppercase font-weight-medium">{{ data.item.name[0] }}</span>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-html="data.item.name"></v-list-item-title>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>

      <v-list>
        <v-list-item
          v-for="(item, i) in selectedList"
          :key="i"
          dense
        >
          <v-list-item-avatar class="list-avatar" :class="item.isGroup ? 'group-bg' : 'avatar'">
            <img
              v-if="item.avatar_url"
              :src="item.avatar_url"
            >

            <v-icon color="secondary" v-else-if="item.isGroup"
            >mdi-account-multiple</v-icon>

            <span v-else class="secondary--text text-uppercase font-weight-medium">{{ item.name[0] }}</span>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-html="item.name"></v-list-item-title>
          </v-list-item-content>

          <v-select
            v-model="item.role"
            :label="$t('Role')"
            :items="roles"
            item-text="title"
            item-value="name"
            class="role-select"
            :rules="rules"
          >
          </v-select>

          <v-list-item-action>
            <v-btn @click="removeUser(item.id)" icon>
              <v-icon size="16" color="grey darken-2">mdi-close-circle</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </template>

    <template v-slot:bottom-actions>
      <v-btn
        @click="deleteShareModal"
        :disabled="loading || !networkDrive || networkDrive.length == 0 ? true : false"
        color="error"
      >
        <v-icon left>mdi-cancel</v-icon>
        {{ $t('delete') }}
      </v-btn>

      <DefaultModal
        @submit="deleteShare"
        :title="$t('delete')"
        ref="deleteNetworkShareModal"
        submit-button-text="delete"
        deleteModal
      >
        <template v-slot:content>
          <span v-html="$t('delete_network_share', {name: networkDriveName})"></span>
        </template>
      </DefaultModal>
    </template>

  </DefaultModal>
</template>

<style scoped>
  .avatar {
    background-color: var(--v-primary-base) !important;
  }

  .list-avatar {
    justify-content: center;
  }

  .role-select {
    width: 132px !important;
    max-width: 132px !important;
  }

  .group-bg {
    background-color: var(--v-primary-base) !important;
  }
</style>

<script>

import {mapActions} from 'vuex'
import i18n from '@/i18n.js'
import AppActions from '@/store/app/actions-types'
import DefaultModal from '@/components/DefaultModal'
import NetworkShareActions from '@/store/content/network_share/actions-types'
import UserActions from '@/store/core/user/actions-types'
import GroupActions from '@/store/core/group/actions-types'

export default {
  name: 'ShareNetworkDriveModal',

  components: {
    DefaultModal,
  },

  data() {
    return {
      isUpdating: false,
      node: null,
      networkDriveName: null,
      rules: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      loading: false,
      items: [],
      selectedUsersAndGroups: [],
      selectedList: [],
      roles: [
        {name: 'write', title: i18n.t('role_writer')},
        {name: 'read', title: i18n.t('role_reader')}
      ],
      networkDrive: null
    }
  },

  computed: {
    normalizedUsersAndGroups() {
      let usersAndGroups = this.items.map(item => {
        return {
          id: item.users_id ? `users-${item.users_id}` : `group-${item.user_group_id}`,
          idRaw: item.users_id || item.user_group_id,
          name: item.name || item.group_title,
          isUser: item.users_id ? true : false,
          isGroup: item.user_group_id ? true : false,
          avatar_url: item.avatar_url || null
        }
      })

      usersAndGroups.sort((a,b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))

      return usersAndGroups
    }
  },

  methods: {
    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE
    ]),

    ...mapActions('content/network_share',
      [
        NetworkShareActions.SAVE_NETWORK_SHARE,
        NetworkShareActions.SAVE_NETWORK_SHARE_PERMISSION,
        NetworkShareActions.FIND_NETWORK_DRIVE,
        NetworkShareActions.DELETE_NETWORK_SHARE_PERMISSION,
        NetworkShareActions.DELETE_NETWORK_SHARE,
      ]
    ),
    ...mapActions('core/user',
      [UserActions.GET_ALL_USERS]
    ),

    ...mapActions('core/group', [
      GroupActions.GET_ALL_GROUPS,
    ]),

    open(node) {
      this.node = node

      this.loading = true
      this.$refs.modal.submitting = false

      this.$refs.modal.openModal()

      this.items = [...[]]
      this.selectedList = [...[]]

      const usersOptions = {
        'filter[tenant_id]': node.tenant_id,
        'filter[include_foreign]': true,
        'limit': 9999,
      }

      const groupsOptions = {
        'filter[tenant]': node.tenant_id,
        'filter[include_foreign]': true,
        'limit': 9999,
      }

      const getUsers = this[UserActions.GET_ALL_USERS](usersOptions)
      getUsers.then((result) => {
        this.items = [...this.items.concat(result.items)]
      })

      const getGroups = this[GroupActions.GET_ALL_GROUPS](groupsOptions)
      getGroups.then((result) => {
        this.items = [...this.items.concat(result.items)]
      })

      const findNetworkDrive = this.getNetworkDrive()

      Promise.all([getUsers, getGroups, findNetworkDrive])
        .then(() => {
          this.createSelectedList()
          this.loading = false
        })
        .catch(() => this.loading = false)
    },

    getNetworkDrive() {
      return this[NetworkShareActions.FIND_NETWORK_DRIVE](this.node.node_id)
        .then((networkDrive) => {
          this.networkDrive = networkDrive
          this.networkDriveName = networkDrive.title
          this.$refs.modal.submitting = false
        })
        .catch(() => {
          this.$refs.modal.submitting = false
        })
    },

    fetchSelectedUsersAndGroups() {
      let selected = []

      this.selectedUsersAndGroups.forEach(
        id => selected.push(this.normalizedUsersAndGroups.find(item => item.id == id))
      )

      selected.sort((a,b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))

      this.selectedList = selected
    },

    createSelectedList() {
      if (!this.networkDrive || !this.networkDrive.title) {
        return
      }

      let selected = []

      this.networkDrive.permissions.forEach((permission) => {
        let user = this.normalizedUsersAndGroups.find(item => item.isUser && item.idRaw == permission.user_id)

        if (user) {
          user.role = permission.permission

          selected.push(user)
        }

        let group = this.normalizedUsersAndGroups.find(item => item.isGroup && item.idRaw == permission.group_id)

        if (group) {
          group.role = permission.permission

          selected.push(group)
        }

        this.selectedList = selected.sort((a,b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
        this.selectedUsersAndGroups = selected.map(item => item.id)
      })
    },

    save() {
      if (this.networkDrive && this.networkDrive.network_share_id) {
        this.updateShare()
      } else {
        this.createShare()
      }
    },

    createShare() {
      let params = {node_id: this.node.node_id, title: this.networkDriveName}

      this[NetworkShareActions.SAVE_NETWORK_SHARE](params)
        .then((result) => {
          let networkShareId = result.network_share_id

          this.$refs.modal.submitting = false

          this.savePermissions(networkShareId, this.selectedList)
            .then(() => {
              this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('success_on_save_network_share'))
            })
            .catch(() => this.$refs.modal.submitting = false)
        })
        .catch(() => {
          this.$refs.modal.submitting = false
        })
    },

    updateShare() {
      let usersInList = this.selectedList.filter(item => item.isUser)
      let groupsInList = this.selectedList.filter(item => item.isGroup)

      let toDelete = this.networkDrive.permissions
        .filter(permission => {
          if ((permission.user_id && usersInList.map(user => user.idRaw).indexOf(permission.user_id) < 0)
              || (permission.group_id && groupsInList.map(group => group.idRaw).indexOf(permission.group_id) < 0)) {
            return true
          }

          if (permission.user_id
            && usersInList.find(user => user.idRaw == permission.user_id).role != permission.permission) {
            return true
          }

          if (permission.group_id
            && groupsInList.find(group => group.idRaw == permission.group_id).role != permission.permission) {
            return true
          }

          return false
        })

      let toDeleteUsersIds = toDelete.filter(item => item.user_id).map(permission => permission.user_id)
      let toDeleteGroupsIds = toDelete.filter(item => item.group_id).map(permission => permission.group_id)
      let usersInPermissionsIds = this
        .networkDrive
        .permissions
        .filter(item => item.user_id)
        .map(permission => permission.user_id)

      let groupsInPermissionsIds = this
        .networkDrive
        .permissions
        .filter(item => item.group_id)
        .map(permission => permission.group_id)

      let toCreate = this.selectedList.filter(item => {
        if (item.isUser && toDeleteUsersIds.indexOf(item.idRaw) > -1) {
          return true
        }

        if (item.isGroup && toDeleteGroupsIds.indexOf(item.idRaw) > -1) {
          return true
        }

        return (item.isUser && usersInPermissionsIds.indexOf(item.idRaw) < 0)
          || (item.isGroup && groupsInPermissionsIds.indexOf(item.idRaw) < 0)
      })

      if (toDelete.length == 0 && toCreate.length == 0) {
        this.$refs.modal.submitting = false
        return
      }

      if (toDelete.length > 0) {
        this.deletePermissions(toDelete)
          .then(() => {
            if (toCreate) {
              this.savePermissions(this.networkDrive.network_share_id, toCreate)
                .then(() => {
                  this.getNetworkDrive()
                    .then(() => {
                      this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('success_on_save_network_share'))
                      this.$refs.modal.submitting = false                
                    })
                })
                .catch(() => this.$refs.modal.submitting = false)
            }
          })
          .catch(() => this.$refs.modal.submitting = false)
      }

      if (toDelete.length == 0 && toCreate.length > 0) {
        this.savePermissions(this.networkDrive.network_share_id, toCreate)
          .then(() => {
            this.getNetworkDrive()
              .then(() => {
                this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('success_on_save_network_share'))
                this.$refs.modal.submitting = false                
              })
          })
          .catch(() => this.$refs.modal.submitting = false)
      }
    },

    savePermissions(networkShareId, selectedList) {
      return new Promise((resolve, reject) => {
        let writers = selectedList.filter(item => item.role == 'write')
        let readers = selectedList.filter(item => item.role == 'read')

        let promises = []

        let params = {}

        if (writers.length > 0) {
          params = {
            'users': writers.filter(item => item.isUser).map(item => item.idRaw),
            'groups': writers.filter(item => item.isGroup).map(item => item.idRaw),
            'permission': 'write'
          }

          promises.push(this[NetworkShareActions.SAVE_NETWORK_SHARE_PERMISSION]({networkShareId, params}))
        }

        if (readers.length > 0) {
          params = {
            'users': readers.filter(item => item.isUser).map(item => item.idRaw),
            'groups': readers.filter(item => item.isGroup).map(item => item.idRaw),
            'permission': 'read'
          }

          promises.push(this[NetworkShareActions.SAVE_NETWORK_SHARE_PERMISSION]({networkShareId, params}))
        }

        Promise.all(promises)
          .then(() => resolve())
          .catch(() => reject())
      })
    },

    deletePermissions(permissions) {
      let promises = []

      permissions.forEach(permission => {
        promises.push(this[NetworkShareActions.DELETE_NETWORK_SHARE_PERMISSION](permission.network_share_perm_id))
      })

      return new Promise((resolve, reject) => {
        Promise.all(promises)
          .then(() => resolve())
          .catch(() => reject())
      })
    },

    removeUser(id) {
      this.selectedList = this.selectedList.filter(item => item.id !== id)
      this.selectedUsersAndGroups = this.selectedUsersAndGroups.filter(userOrGroupId => id !== userOrGroupId)
    },

    deleteShareModal() {
      this.$refs.deleteNetworkShareModal.openModal()
    },

    deleteShare() {
      this.loading = true

      this[NetworkShareActions.DELETE_NETWORK_SHARE](this.networkDrive.network_share_id)
        .then(() => {
          this.$refs.deleteNetworkShareModal.closeModal()

          this.open(this.node)
        })
    },
  }
}
</script>
