<template>
  <v-container :class="containerClass">
    
      <v-row class="row-container fix-height">
        <v-col class="align-center text-start">
          <Breadcrumbs :items="breadcrumbsItems" />
        </v-col>
          
        <v-col 
          v-if="showInactiveFilter" 
          cols="auto" 
          class="align-center text-end inactive-filter"
        >
          <v-switch
            :label="$t('show_inactive')"
            :input-value="inactiveFilterValue"
            @change="$emit('switch-inactive')"
          >
          </v-switch>
        </v-col>

        <v-col 
          v-if="showSearchBar" 
          cols="auto" 
          class="text-end pt-0"
        >
          <v-text-field
            hide-details
            append-icon="mdi-magnify"
            :label="$t('search')"
            @change="searchValueChanged"
          >
          </v-text-field>
        </v-col>

        <v-col cols="auto" class="align-center text-end">
          <slot name="actions">
            <v-btn
              v-for="(action, index) in actions"
              :key="index"
              :color="action.color ? action.color : 'primary'"
              v-on:click="$emit(action.eventToEmit)"
              class="action-btn"
            >
              {{ $t(action.text) }}
            </v-btn>
          </slot>
        </v-col>
      </v-row>

    <v-container class="table-container">
      <slot name="content"></slot>
    </v-container>

  </v-container>
</template>

<style lang="scss" scoped>
  @import '@/styles/container-width.scss';
</style>

<style scoped>
  .container {
    padding: 0;
  }

  .table-container {
    margin-top: 20px;
  }

  .row-container {
    margin-top: 10px;
    padding: 0 10px 0 10px;
  }

  .align-center {
    align-self: center;
  }

  .icon-custom {
    margin-bottom: 8px;
  }

  .action-btn {
    margin-left: 10px;
  }

  .inactive-filter {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-right: 28px !important;
  }

  .fix-height {
    min-height: 74px;
  }
</style>

<script>

import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
  name: "DefaultCrudView",

  components : {
    Breadcrumbs
  },

  props: {
    breadcrumbsItems: {
      type: Array
    },
    
    showInactiveFilter: {
      type: Boolean
    },

    inactiveFilterValue: {
      type: Boolean
    },

    showSearchBar: {
      type: Boolean
    },

    actions: {
      type: Array
    },

    containerLarge: {
      type: Boolean,
      value: false
    },

    containerMedium: {
      type: Boolean,
      value: false
    },

    searchValue: {
      type: String
    }
  },

  computed: {
    containerClass() {
      if(this.containerMedium)
        return "custom-container-medium"

      if(this.containerLarge)
        return "custom-container-large"

      return "custom-container"
    }
  },

  methods: {
    searchValueChanged(value) {
      this.$emit('search-value-changed', value)
    }
  }
}

</script>