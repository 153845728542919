<template>
  <DefaultModal
    ref="modal"
    @submit="checkMarksPermission"
    :loading="loading"
    scrollable
  >
    <template v-slot:title>
      <span class="title-text">
        <span class="headline">{{ $t('security_classification') }}</span>
        <span class="font-italic normal"> - {{ nodeName }}</span>
      </span>

      <v-spacer></v-spacer>

      <ClassificationHelper :open="helper" />
    </template>

    <template v-slot:content>
      <v-sheet min-height="400">

        <div
          v-for="(securityGroup, index) in securityGroups"
          :key="securityGroup.security_group_id"
        >

          <v-treeview
            :items="securityGroup.tree"
            :open.sync="allMarks[securityGroup.security_group_id]"
            :ref="'secgroup_' + securityGroup.security_group_id"
            selection-type="independent"
            disable-per-node
            transition
            open-all
          >
            <template
              v-slot:prepend="{ item }"
            >
              <template v-if="! item.title_item">
                <v-checkbox
                  v-if="securityGroup.node_cardinality == 'single'"
                  v-model="classificationBySecurityGroup[securityGroup.security_group_id]"
                  :on-icon="'mdi-radiobox-marked'"
                  :off-icon="'mdi-radiobox-blank'"
                  :value="item.id"
                  :disabled="item.disabled"
                  @click.native.prevent.stop.capture="classificationChangeSingleCardinality(securityGroup.security_group_id, item.id)"
                ></v-checkbox>

                <v-checkbox
                  v-else
                  v-model="classificationBySecurityGroup[securityGroup.security_group_id]"
                  :disabled="item.disabled"
                  :on-icon="item.has_classification && item.overwritten ? '$checkbox_eye_off' : '$checkboxOn'"
                  :off-icon="item.has_mark_below ? '$checkbox_eye' : '$checkboxOff'"
                  :value="item.id"
                  @change="classificationChange(item)"
                ></v-checkbox>
              </template>
            </template>

            <template
              v-slot:label="{ item }"
            >

              <template v-if="item.title_item">
                <h3 class="sg-key">
                  <span>{{ securityGroup.security_group_key }}</span>
                  <span class="font-italic normal">{{ securityGroup.recursive ? ` (${$t('recursive')})` : ''}}</span>
                </h3>
              </template>

              <template v-else>
                <span>{{item.name}}</span> <span v-if="item.message" class="font-italic">({{ item.message }})</span>
              </template>

            </template>
          </v-treeview>

          <v-divider
            v-if="index < securityGroups.length - 1"
            :key="index"
          ></v-divider>
        </div>
      </v-sheet>

      <DefaultModal
        :title="$t('confirm_classification_title')"
        @submit="saveClassification"
        ref="confirmModal"
        submit-button-text="confirm"
        deleteModal
      >
        <template v-slot:content>
          {{ $t('confirm_classification') }}?
        </template>
      </DefaultModal>

    </template>
  </DefaultModal>
</template>

<style scoped>
  >>>span.v-icon {
    color: var(--v-primary-base)
  }

  .title-text {
    font-weight: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .sg-key {
    display: inline;
  }

  >>>.v-treeview-node__prepend:empty {
    min-width: unset;
  }
</style>

<script>
import Vue from 'vue'

import { mapActions } from 'vuex'

import AppActions from '@/store/app/actions-types'
import EntryActions from '@/store/content/entry/actions-types'
import ClassificationActions from '@/store/operation/classification/actions-types'
import ClearanceActions from '@/store/operation/clearance/actions-types'
import SecurityGroupActions from '@/store/operation/security_group/actions-types'

import DefaultModal from '@/components/DefaultModal'
import ClassificationHelper from '@/components/node/ClassificationHelper'

export default {
  name: 'SecurityClassificationModal',

  components: {
    DefaultModal,
    ClassificationHelper,
  },

  data: () => ({
    loading: false,
    nodeId: null,
    nodeName: "",
    helper: false,
    nodeParents: [],
    userClearance: [],
    allClassification: [],
    securityGroups: [],
    allMarks: [],
    classificationBySecurityGroup: [],
  }),

  computed: {
    userClearanceIds() {
      return this.userClearance.map(clearance => clearance.security_mark_id)
    },

    recursiveMarks() {
      return this.allClassification.filter(classi => classi.is_recursive).map(classi => classi.security_mark_id)
    },
  },

  methods: {
    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE,
    ]),

    ...mapActions('content/entry', [
      EntryActions.GET_ENTRY_PARENTS_IDS,
    ]),

    ...mapActions('operation/security_group', [
      SecurityGroupActions.GET_SECURITY_GROUPS_BY_NODE,
      SecurityGroupActions.GET_SECURITY_MARKS_BY_SECURITY_GROUP,
    ]),

    ...mapActions('operation/classification', [
      ClassificationActions.GET_CLASSIFICATION_RECURSIVE_BY_NODE,
      ClassificationActions.SIMULATE_MARKS_PERMISSION,
      ClassificationActions.SAVE_SECURITY_CLASSIFICATION,
    ]),

    ...mapActions('operation/clearance', [
      ClearanceActions.GET_USER_GROUPS_CLEARANCES,
    ]),

    open(nodeId, nodeName = "") {
      this.nodeId = nodeId
      this.nodeName = nodeName

      this.fetchData()
      this.$refs.modal.openModal()
    },

    close() {
      this.groupId = null
      this.$refs.modal.closeModal()
    },

    fetchData() {
      this.loading = true

      const nodeParentsPromise = this[EntryActions.GET_ENTRY_PARENTS_IDS](this.nodeId)
      nodeParentsPromise.then((result) => {
        this.nodeParents = [...result.map(np => np.node_id)]
      })

      const clearancePromise = this[ClearanceActions.GET_USER_GROUPS_CLEARANCES]()
      clearancePromise.then((result) => {
        this.userClearance = [...result]
      })

      const classificationPromise = this[ClassificationActions.GET_CLASSIFICATION_RECURSIVE_BY_NODE](this.nodeId)
      classificationPromise.then((result) => {
        this.allClassification = [...result]
      })

      const securityGroupsPromise = this[SecurityGroupActions.GET_SECURITY_GROUPS_BY_NODE](this.nodeId)
      securityGroupsPromise.then((result) => {
        let securityGroups = [...result.items]

        this.classificationBySecurityGroup = [...[]]

        securityGroups.forEach(secGroup => {
          this.findSecurityMarksBySecurityGroup(secGroup)
        })

        this.securityGroups = [...securityGroups]
      })

      Promise.all([nodeParentsPromise, clearancePromise, classificationPromise, securityGroupsPromise])
        .finally(() => this.loading = false)
    },

    findSecurityMarksBySecurityGroup(secGroup) {
      this[SecurityGroupActions.GET_SECURITY_MARKS_BY_SECURITY_GROUP](secGroup.security_group_id)
        .then((result) => {
          let allMarks = result.items.map(mark => mark.security_mark_id)
          this.allMarks[secGroup.security_group_id] = allMarks.concat(secGroup.security_group_key)

          Vue.set(secGroup, 'marks', result.items)

          this.setClassificationBySecurityGroup(secGroup)

          Vue.set(secGroup, 'tree', this.fetchSecurityGroupTreeviewItems(secGroup))
        })
    },

    fetchSecurityGroupTreeviewItems(securityGroup) {
      let item = {
        id: securityGroup.security_group_key,
        title_item: true,
        children: [],
      }

      if (securityGroup.match_type == 'tree') {
        securityGroup.marks.forEach((mark) => {
          if (mark.mark_parent == null) {
            item.children.push(this.buildTreeviewItem(mark, securityGroup.marks))
          }
        })
      }

      if (securityGroup.match_type != 'tree') {
        securityGroup.marks.forEach(mark => {
          item.children.push(this.buildTreeviewItem(mark))
        })
      }

      return [item]
    },

    fetchTreeviewChildrenItems(securityMarkId, marks) {
      let items = []

      marks.forEach(mark => {
        if (mark.mark_parent == securityMarkId) {
          items.push(this.buildTreeviewItem(mark, marks))
        }
      })

      return items
    },

    buildTreeviewItem(mark, treeMarks = null) {
      const classification = this.classificationBySecurityGroup[mark.security_group_id].find(classif => classif == mark.security_mark_id)

      const isRecursive = this.recursiveMarks.includes(classification)

      const markTreeUpwardsMarks = mark.tree_path_up.split(',').map( Number )
      const markTreeDownwardsMarks = mark.tree_path_down.split(',').map( Number )

      let markAndClass = {...mark, classification: this.allClassification.find(classif => classif.security_mark_id == classification) }

      const hasMarkAbove = this.hasMarkInTreePath(markAndClass, markTreeUpwardsMarks)
      const hasMarkBelow = this.hasMarkInTreePath(markAndClass, markTreeDownwardsMarks)
      const overwritten = (hasMarkAbove && isRecursive)

      let message = ""
      if (classification) {
        message = this.buildMarkVisibilityMessage(isRecursive, overwritten, hasMarkBelow)
      }

      let item =
      { ...mark,
        id: mark.security_mark_id,
        name: mark.security_mark_key,
        is_recursive: isRecursive,
        has_classification: classification ? true : false,
        has_mark_below: hasMarkBelow,
        overwritten: overwritten,
        message: message,
        disabled: (! this.hasClearanceForMark(markTreeUpwardsMarks) || isRecursive),
      }

      if (treeMarks) {
        item =
        {...item,
          children: this.fetchTreeviewChildrenItems(mark.security_mark_id, treeMarks),
        }
      }

      return item
    },

    hasMarkInTreePath(mark, treePath) {
      const thisMarkIndex = this.nodeParents.indexOf(mark.classification?.node_id)

      const markSecurityGroup = this.securityGroups.find(sg => sg.security_group_id == mark.security_group_id)

      const isSingleCardinality = markSecurityGroup?.node_cardinality == 'single' ?? false

      const matchedClassifications = this.classificationBySecurityGroup[mark.security_group_id].filter(
        markId => {
          let mostLocalMark = true
          const thisClassification = this.allClassification.find(classif => classif.security_mark_id == markId)

          if (mark.classification && thisClassification) {
            const thisClassificationIndex = this.nodeParents.indexOf(thisClassification.node_id)

            mostLocalMark = thisMarkIndex >= thisClassificationIndex
          }

          if (isSingleCardinality) {
            return markId != mark.security_mark_id && mostLocalMark
          }

          return treePath.includes(markId) && markId != mark.security_mark_id && mostLocalMark
        }
      )

      return (matchedClassifications.length > 0)
    },

    hasClearanceForMark(markTreeUpwardsMarks) {
      const matchedClassifications = this.userClearanceIds.filter(clearance => markTreeUpwardsMarks.includes(clearance))

      return (matchedClassifications.length > 0)
    },

    buildMarkVisibilityMessage(isRecursive, overwritten, hasMarkBelow) {
      let message = "local - in effect"

      if (isRecursive) {
        message = "inherited"

        if (overwritten) {
          message += " - overwritten"
        } else if (! hasMarkBelow) {
          message += " - in effect"
        }
      }

      return message
    },

    classificationChange(mark) {
      const markTreeDownwardsMarks = mark.tree_path_down.split(',').map( Number );
      const markTreeUpwardsPath = mark.tree_path_up.split(',').map( Number );

      const marksToDeclassify = [...markTreeDownwardsMarks, ...markTreeUpwardsPath]
        .filter(mark => !this.recursiveMarks.includes(mark))

      const filteredClassifications = this.classificationBySecurityGroup[mark.security_group_id].filter(
        classi => (! marksToDeclassify.includes(classi)) || classi == mark.security_mark_id
      )

      this.classificationBySecurityGroup[mark.security_group_id] = [...filteredClassifications]

      this.updateSecurityGroupTree(mark.security_group_id)
    },

    classificationChangeSingleCardinality(secGroupId, value) {
      const currentValue = this.classificationBySecurityGroup[secGroupId]

      if (currentValue.length > 0 && ! this.hasClearanceForMark(currentValue)) {
        return
      }

      const newValue = [
        ...currentValue.includes(value) ? [] : [value],
        ...currentValue.filter(cv => this.recursiveMarks.includes(cv))
      ]

      Vue.set(
        this.classificationBySecurityGroup,
        secGroupId,
        newValue
      )

      this.updateSecurityGroupTree(secGroupId)
    },

    updateSecurityGroupTree(securityGroupId) {
      const securityGroup = this.securityGroups.find(sg => sg.security_group_id == securityGroupId)
      const sgIndex = this.securityGroups.indexOf(securityGroup)

      Vue.set(this.securityGroups[sgIndex], 'tree', this.fetchSecurityGroupTreeviewItems(securityGroup))
    },

    setClassificationBySecurityGroup(securityGroup) {
      const securityGroupId = securityGroup.security_group_id

      if (!this.allMarks.length || !this.allMarks[securityGroupId]) {
        return
      }

      const markIds = this.allMarks[securityGroupId]

      let selectedClassification = this
        .allClassification
        .filter(classification => markIds.includes(classification.security_mark_id))
        .map(mark => mark.security_mark_id)

      Vue.set(this.classificationBySecurityGroup, securityGroupId, selectedClassification)
    },

    checkMarksPermission() {
      let classificationMarks = []

      this.classificationBySecurityGroup.forEach((items) => {
        let filteredItems = items.filter(i => ! this.recursiveMarks.includes(i))
        if (filteredItems.length == 0) {
          filteredItems = items
        }

        classificationMarks = classificationMarks.concat(filteredItems)
      })

      this[ClassificationActions.SIMULATE_MARKS_PERMISSION]({marks: classificationMarks})
        .then((response) => {
          if (response.result) {
            this.saveClassification()
          } else {
            this.$refs.confirmModal.openModal()
          }
        })
        .finally(() => this.$refs.modal.submitting = false)
    },

    saveClassification() {
      this.$refs.modal.submitting = true

      let classification = []

      this.classificationBySecurityGroup.forEach((items, index) => {
        let filteredItems = items.filter(i => ! this.recursiveMarks.includes(i))

        classification.push({node_id: this.nodeId, security_group_id: index, security_marks: filteredItems})
      })

      this[ClassificationActions.SAVE_SECURITY_CLASSIFICATION](classification)
        .then(() => {
          this.$refs.modal.submitting = false
          this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('Success on saving classification'))
          this.$refs.modal.closeModal()
        })
        .catch(() => this.$refs.modal.submitting = false)
    }
  },
}
</script>
