<template>
  <DefaultModal
    :title="$t('convert_to_gdocs')"
    :hide-save-button="true"
    ref="modal"
  >
    <template v-slot:content>
      <v-card
        tile
        flat
      >
        <v-card-text
          class="progress-view"
          align="center"
        >
          <v-icon
            :color="icon.color || 'defaultIconColor'"
            v-text="icon.icon"
            large
          ></v-icon>

          <v-progress-linear
            color="info lighten-2"
            class="progress-bar"
            indeterminate
            rounded
          ></v-progress-linear>

          <v-icon
            color="defaultIconColor"
            v-text="'mdi-google-drive'"
            large
          ></v-icon>
        </v-card-text>

        <v-card-subtitle
          align="center"
          v-text="$t('convert_to_gdocs_progress')"
        ></v-card-subtitle>
      </v-card>

    </template>
  </DefaultModal>
</template>

<style scoped>
  .progress-view {
    display: block ruby;
  }

  .progress-bar {
    width: 70%;
    margin: 0 10px 0 10px;
  }
</style>

<script>

import { mapActions } from 'vuex'

import IconsByMimetype from '@/utils/iconsByMimetype.js'
import DefaultModal from '@/components/DefaultModal'

import AppActions from '@/store/app/actions-types'
import ContentActions from '@/store/content/actions/actions-types'
import EntryActions from '@/store/content/entry/actions-types'

export default {
  name: 'ConvertToGDocModal',

  components: {
    DefaultModal,
  },

  data: () => ({
    loading: false,
    nodeId: null,
    mimeType: null,
  }),

  computed: {
    icon() {
      return IconsByMimetype.getIconByMimetype(this.mimeType)
    },
  },

  methods: {
    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE
    ]),

    ...mapActions('content/actions', [
      ContentActions.CONVERT_TO_GDOC,
    ]),

    ...mapActions('content/entry', [
      EntryActions.LIST_SUBFOLDER,
    ]),

    open(nodeId, mimeType) {
      this.nodeId = nodeId
      this.mimeType = mimeType

      this.$refs.modal.openModal()

      this.convert()
    },

    close() {
      this.loading = false
      this.nodeId = null
      this.mimeType = null

      this.$refs.modal?.closeModal()
    },

    convert() {
      this.loading = true

      this[ContentActions.CONVERT_TO_GDOC](this.nodeId)
        .then(() => {
          const parentId = this.$route.params.parentId

          if (parentId) {
            this[EntryActions.LIST_SUBFOLDER]({parentId})
              .finally(() => { this.close() })
          }

          this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('conversion_to_gdocs_successful'))
        })
    },
  },
}

</script>
