<template>
  <div class="image-preview-container">
    <v-img
      v-if="previewsUrls.length === 1"
      :src="singlePagePreviewUrl"
      max-height="900"
      max-width="900"
      contain
    ></v-img>

    <v-virtual-scroll
      v-else
      :items="previewsUrls"
      :bench="previewsUrls.length"
      :item-height="900"
      class="d-flex justify-center"
      max-height="900"
    >
      <template v-slot:default="{ item, index }" >
        <v-list-item
          :id="index"
          v-intersect.quiet="{
            handler: onIntersect,
            options: {
              threshold: [0.75]
            }
          }"
        >
          <v-img
            :src="item"
            max-height="900"
            max-width="900"
            contain
            eager
          ></v-img>
        </v-list-item>
      </template>
    </v-virtual-scroll>

    <v-chip
      v-if="previewsUrls.length > 1"
      class="page-indicator"
      label
      dark
    >
      {{ $t('page') }} {{ currentPage }}/{{ previewsUrls.length }}
    </v-chip>

  </div>
</template>

<style scoped>
  .image-preview-container{
    display: contents;
  }

  >>>.v-virtual-scroll__item + .v-virtual-scroll__item {
    margin-top: 5%;
  }

  >>>.v-virtual-scroll__item {
    top: unset !important;
    position: relative !important;
  }

  .page-indicator {
    position: absolute;
    bottom: 5%;
  }
</style>

<script>

export default {
  name: 'ImagePreview',

  props: {
    previewsUrls: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    intersectionOffset: 0,
  }),

  computed: {
    singlePagePreviewUrl() {
      return this.previewsUrls[0]
    },

    currentPage() {
      return this.intersectionOffset + 1
    },
  },

  methods: {
    onIntersect (isIntersecting, {}, {}) {
      if (isIntersecting) {
        this.intersectionOffset = parseInt(isIntersecting[0].target.id)
      }
    },
  },
}

</script>
