var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"outlined":"","color":hover ? 'grey lighten-3' : 'white'}},[[_c('v-checkbox',{staticStyle:{"margin":"8px","position":"absolute","z-index":"2"},attrs:{"align":"start"},on:{"click":_vm.toggleSelection},model:{value:(_vm.isSelected),callback:function ($$v) {_vm.isSelected=$$v},expression:"isSelected"}})],(_vm.thumbUrl)?_c('v-img',{staticClass:"white--text align-center img-bg custom-thumb",attrs:{"height":"200px","src":_vm.thumbUrl,"align":"center","justify":"center","contain":""},on:{"click":function($event){$event.stopPropagation();return _vm.openPreviewModal($event)}}}):_c('v-img',{staticClass:"white--text align-center img-bg custom-thumb",attrs:{"height":"200px","align":"center","justify":"center","contain":""},on:{"click":function($event){$event.stopPropagation();return _vm.openPreviewModal($event)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-icon',{attrs:{"color":_vm.icon.color || 'defaultIconColor',"size":"150"},domProps:{"textContent":_vm._s(_vm.icon.icon)}})],1)]},proxy:true}],null,true)},[(_vm.isPlayableType())?_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-play-circle")]):_vm._e()],1),_c('v-card-actions',{staticClass:"card-actions"},[_c('v-icon',{attrs:{"color":_vm.icon.color || 'defaultIconColor'},domProps:{"textContent":_vm._s(_vm.icon.icon)}}),_c('v-tooltip',{attrs:{"open-delay":"1000","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card-subtitle',_vm._g(_vm._b({staticClass:"custom-subtitle",domProps:{"textContent":_vm._s(_vm.data.name)}},'v-card-subtitle',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.data.name))])]),_c('v-spacer'),(!_vm.disableActionsMenu)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}],null,true)},[_c('ContentActionsPopup',{attrs:{"data":_vm.data,"filter-type":_vm.fileNodeType()},on:{"preview":_vm.openPreviewModal,"move-success":function($event){return _vm.$emit('move-success')}}})],1):_vm._e()],1),_c('PreviewModal',{ref:"previewModal",attrs:{"node":_vm.data,"file-icon":_vm.icon}})],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }