<template>
    <DefaultModal
      :title="$t('share_file')"
      ref="modal"
      @submit="save"
      :loading="loading"
      max-width="502px"
      :submit-button-text="'send_email'"
    >
    <template v-slot:content>
      <UserEmailTextarea
        :messages="$t('message_email_separeted_comma')"
        v-model="emailsToSend"
        :rules="required.concat(emailRules)"
      ></UserEmailTextarea>
      <v-text-field
        :label="$t('subject')"
        outlined
        dense
        v-model="subject"
      ></v-text-field>
      <v-text-field
        :label="$t('message')"
        outlined
        dense
        v-model="message"
      ></v-text-field>
      <p><span class="subtitle">{{ $t('link_expiration_datetime') }}</span></p>
      <v-row>
        <v-col
          sm="6"
        >
        <v-menu
          ref="menu"
          v-model="dateTimeMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="expirationTime"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="expirationTime"
              :label="$t('hour')"
              prepend-icon="mdi-clock-time-four-outline"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="dateTimeMenu"
            v-model="expirationTime"
            full-width
            @click:minute="$refs.menu.save(expirationTime)"
          ></v-time-picker>
        </v-menu>
      </v-col>
      <v-col
        sm="6"
      >
        <v-menu
          v-model="dateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="expirationDate"
              :label="$t('date')"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            :min="startDate"
            v-model="expirationDate"
            @input="dateMenu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-text-field
        :label="$t('download_link')"
        outlined
        dense
        v-model="downloadLink"
        readonly
      ></v-text-field>
      <v-row>
        <v-col
          sm="12"
        >
          <v-btn
            type="button"
            class="primary"
            :disabled="dateIsInvalid"
            @click="printDownloadLink">{{  $t("generate_download_link") }}
          </v-btn>
        </v-col>
          <v-col
            sm="12"
          >
            <v-alert dense type="error" v-model="showErrorTooltip" variant="outlined">
              {{ $t('error_date_less_than_current') }}
            </v-alert>
          </v-col>
        </v-row>
    </template>
  </DefaultModal>
</template>
<style scoped>
.subtitle {
  font-size: 16px;
  text-align: left;
  letter-spacing: 0.45px;
  color: rgb(60, 64, 67);
  opacity: 1;
}
</style>
<script>
import {mapActions} from 'vuex'
import DefaultModal from '@/components/DefaultModal'
import ContentActions from '@/store/content/actions/actions-types'
import UserEmailTextarea from '@/components/core/UserEmailTextarea'
import NotificationActions from '@/store/core/notification/actions-types'
import AppActions from '@/store/app/actions-types'


export default {
  name: 'ShareModal',

  components: {
    DefaultModal,
    UserEmailTextarea
  },

  data() {
      return {
        loading: false,
        message: '',
        subject: '',
        emailsToSend: [],
        downloadLink: '',
        required: [
          v => !!v || this.$t('form_rules.mandatory')
        ],
        emailRules: [
          v => !v || /^([a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*)(([, ]+[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])\.([a-z0-9]([a-z0-9-]*[a-z0-9]))*)?)*$/.test(v) || this.$t('message_invalid_email')
        ],
        expirationTime: null,
        expirationDate:  null,
        dateTimeMenu: false,
        dateMenu: false,
        startDate: null,
        showErrorTooltip: false,
        linkGenerationTimeStamp: null
      };
  },

  computed: {
    dateIsInvalid() {
      return this.checkDateIsInvalid()
    },
  },

  methods: {
    ...mapActions('content/actions',
      [ContentActions.GENERATE_DOWNLOAD_LINK]
    ),

    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE,
      AppActions.OPEN_APP_ERROR_MESSAGE
    ]),

    ...mapActions('core/notification', [
      NotificationActions.SEND_SHARE_MESSAGE,
    ]),

    open(data) {
      this.node = data
      this.$refs.modal.submitting = false
      this.$refs.modal.openModal()
      let now = new Date;
      this.startDate = now.toISOString().substr(0, 10)
      this.expirationDate = (new Date(now.getTime()+(2*24*60*60*1000) - (now).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      this.expirationTime = now.toLocaleTimeString(undefined, { hour12: false }).slice(0, -3)
      this.downloadLink = null
      this.emails = null
      this.linkGenerationTimeStamp = null
    },

    async save() {
      if (this.checkDateIsInvalid()) {
        return false;
      }

      this.loading = true;

      if (this.emails === '') {
        return false;
      }

      if (!this.downloadLink || this.linkGenerationTimeStamp != this.getSelectedExpirationDate()) {
        await this.createDownloadLink(this.node.node_id)
      }

      const options = {
        subject: (this.subject || `[${this.$t('share_file_email_subject')}: ${this.node.name}]`),
        content: (this.message || '') + `<br>` + `<a href="`+this.downloadLink+`">` + this.node.name + `</a>`,
        emails: this.emailsToSend
      }

      this[NotificationActions.SEND_SHARE_MESSAGE](options)
        .then(() => {
            this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('send_mail_successfully'))
        })
        .catch(() =>
          this[AppActions.OPEN_APP_ERROR_MESSAGE](this.$t('send_mail_error'))
        )
        .finally(() => {
          this.$refs.modal.submitting = false
          this.$refs.modal.loading = false;
          this.$refs.modal.closeModal()
        })
    },

    printDownloadLink() {
      if (! this.checkDateIsInvalid()) {
        this.createDownloadLink(this.node.node_id)
      }
    },

    getSelectedExpirationDate() {
      let dateTime = this.expirationDate
      dateTime = dateTime.concat(' ', this.expirationTime)

      return Date.parse(dateTime) / 1000
    },

    createDownloadLink(nodeId) {
      this.loading = true;
      let dateTime = this.getSelectedExpirationDate()

      this.linkGenerationTimeStamp = dateTime

      let params = { expiration_date : dateTime }

      return this[ContentActions.GENERATE_DOWNLOAD_LINK]({nodeId, params})
        .then((result) => {
          this.downloadLink = result
        })
        .catch(() => {
          this[AppActions.OPEN_APP_ERROR_MESSAGE](this.$t('share_link_error'))
        })
        .finally(() => {
          this.loading = false;
        })
    },

    checkDateIsInvalid() {
      if (this.expirationDate !== null && this.expirationTime !== null) {
        let error = (Date.parse(this.expirationDate.concat(' ', this.expirationTime)) / 1000 < Math.ceil((new Date).getTime()/1000))
        this.showErrorTooltip = error
        return error
      }

      this.showErrorTooltip = false

      return false;
    }
  }
}
</script>