export default {
  update(newWindow, newText, newIcon = null) {
    newWindow.document.title = newText
    
    if(newIcon) {
      const head = newWindow.document.getElementsByTagName('head')[0]
      const existingFavicons = head.querySelectorAll('link[rel="icon"]')
      const newIconPath = `/img/tab_icons/${newIcon}.png`
      existingFavicons.forEach(favicon => favicon.href = newIconPath)
    }
  }
}