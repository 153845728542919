<template>
  <v-hover>
    <template v-slot:default="{ hover }">
      <v-card
        outlined
        :color="hover ? 'grey lighten-3' : 'white'"
      >
      <template>
          <v-checkbox
            align="start"
            v-model="isSelected"
            @click="toggleSelection"
            style="margin: 8px; position: absolute; z-index: 2;"
          ></v-checkbox>
        </template>

        <v-img
          class="white--text align-center img-bg custom-thumb"
          @click.stop="openPreviewModal"
          height="200px"
          v-if="thumbUrl"
          :src="thumbUrl"
          align="center"
          justify="center"
          contain
        ></v-img>

        <v-img
          class="white--text align-center img-bg custom-thumb"
          @click.stop="openPreviewModal"
          height="200px"
          v-else
          align="center"
          justify="center"
          contain
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-icon
                :color="icon.color || 'defaultIconColor'"
                v-text="icon.icon"
                size="150"
              ></v-icon>
            </v-row>
          </template>

          <v-icon
            v-if="isPlayableType()"
            x-large
          >mdi-play-circle</v-icon>

        </v-img>

        <v-card-actions class="card-actions">

          <v-icon
            :color="icon.color || 'defaultIconColor'"
            v-text="icon.icon"
          ></v-icon>

          <v-tooltip open-delay="1000" bottom>
            <template v-slot:activator="{ on, attrs }">

              <v-card-subtitle
                v-bind="attrs"
                v-on="on"
                class="custom-subtitle"
                v-text="data.name"
              ></v-card-subtitle>

            </template>
            <span>{{ data.name }}</span>
          </v-tooltip>

          <v-spacer></v-spacer>

          <v-menu
            v-if="!disableActionsMenu"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>

            <ContentActionsPopup
              :data="data"
              :filter-type="fileNodeType()"
              @preview="openPreviewModal"
              @move-success="$emit('move-success')"
            />
          </v-menu>

        </v-card-actions>

        <PreviewModal ref="previewModal" :node="data" :file-icon="icon" />
      </v-card>
    </template>
  </v-hover>
</template>

<style scoped>
  .custom-thumb {
    cursor: pointer;
  }

  .img-bg {
    background-color: #DADCE0;
  }

  .preview-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
  }

  .preview {
    position: absolute;
    top: 25%;
    left: 25%;
    width: 50%;
    height: 50%;
  }

  .card-actions {
    padding: 1px 8px 1px 8px;
  }

  .custom-subtitle {
    font-weight: 500;
    color: rgb(60, 64, 67) !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 16px 12px !important;
  }

  .v-card {
    border-color: #DADCE0 !important;
  }
</style>

<script>

import IconsByMimetype from '@/utils/iconsByMimetype.js'
import NodeTypes from '@/store/content/actions/node-types.js'

import PreviewModal from '@/components/content/preview/PreviewModal'

export default {
  name: 'FileCard',

  components: {
    PreviewModal
  },

  beforeCreate: function () {
    this.$options.components.ContentActionsPopup = require('@/components/content/ContentActionsPopup.vue').default
  },

  props: {
    data: {
      type: Object
    },

    disableActionsMenu: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      preview: false,
      isSelected: false
    }
  },

  computed: {
    icon() {
      return IconsByMimetype.getIconByMimetype(this.data.extension, this.data.mime)
    },

    thumbUrl() {
      if (Array.isArray(this.data.renditions)) {
        return this.data.renditions[0]
      }

      if (typeof this.data.renditions === 'object') {
        return Object.values(this.data.renditions)[0]
      }

      return false;
    }
  },

  methods: {
    isPlayableType() {
      const mimetype = this.data.mime ?? ""

      if (mimetype.search('video|audio|mxf') >= 0) {
        return true
      }

      return false
    },

    fileNodeType() {
      return NodeTypes.NODE_FILE
    },

    openPreviewModal() {
      this.$refs.previewModal.openModal()
    },

    toggleSelection() {
      this.$emit('toggle-selection', this.data.node_id);
    },

    markSelectionAs(selected) {
      this.isSelected = selected
    },


  },
}

</script>
