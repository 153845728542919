<template>
  <v-dialog
    v-model="modal"
    fullscreen
  >
    <v-toolbar
      absolute
      dark
      dense
      flat
      tile
    >
      <v-btn
        @click="closeModal"
        icon
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-icon
        :color="fileIcon.color || 'defaultIconColor'"
      >
        {{ fileIcon.icon }}
      </v-icon>

      <v-toolbar-title>
        {{node.name}}
      </v-toolbar-title>

      <v-spacer></v-spacer>
    </v-toolbar>

    <v-card
      class="preview-container"
      @click.stop="closeModal"
    >
      <v-progress-circular
        v-if="loading"
        indeterminate
        color="primary"
        :size="70"
      ></v-progress-circular>

      <v-alert
        v-else-if="error || (previewsUrls.length === 0)"
        type="error"
      >{{ $t('preview_unavailable') }}</v-alert>

      <VideoPreview v-else-if="isPlayableType()" :previews-urls="previewsUrls" :modal="modal" />
      <ImagePreview v-else :previews-urls="previewsUrls" />
    </v-card>

  </v-dialog>
</template>

<style scoped>
  .preview-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .7);
    padding-top: 3% !important;
    cursor: default;
  }
</style>

<script>

import { mapActions } from 'vuex'
import _ from 'lodash';

import ImagePreview from '@/components/content/preview/ImagePreview'
import VideoPreview from '@/components/content/preview/VideoPreview'

import RenditionActions from '@/store/content/rendition/actions-types'

export default {
  name: 'PreviewModal',

  components:{
    ImagePreview,
    VideoPreview
  },

  props: {
    node: {
      type: Object
    },
    fileIcon: {
      type: Object,
      default: () => {
        return {
          name: 'mdi-image',
          color: 'white',
        }
      }
    },
  },

  data: () => ({
    modal: false,
    loading: false,
    error: false,
    renditions: [],
  }),

  computed: {
    previewsUrls() {
      return _.map(
        _.orderBy(
          this.renditions,
          (item) => {
            return new Number(item.item_index)
          },
          ["asc"]
        )
      , 'url')
    },

    currentPage() {
      return this.intersectionOffset + 1
    },
  },

  methods: {
    ...mapActions('content/rendition', [
      RenditionActions.GET_PREVIEW,
    ]),

    toggleModal() {
      this.modal = !this.modal
    },

    openModal() {
      this.modal = true

      this.fetchData()
    },

    closeModal() {
      this.modal = false
    },

    isPlayableType() {
      const mimetype = this.node.mime ?? ""

      if (mimetype.search('video|audio|mxf') >= 0) {
        return true
      }

      return false
    },

    fetchData() {
      this.loading = true
      this.error = false

      this[RenditionActions.GET_PREVIEW](this.node.node_id)
        .then((result) => {
          this.renditions = [...result]

          this.loading = false
        })
        .catch(() => {
          this.loading = false
          this.error = true
        })
    },
  },
}

</script>
