<template>
  <v-card
    :loading="loading"
    class="card"
  >
    <v-list dense>

      <template v-for="(item, i) in availableActions" >

        <v-divider
          :key="item.key + ' divider'"
          v-if="item.divider && i !== 0"
        ></v-divider>

        <v-menu
          v-if="item.callMethod == 'customAction'"
          :key="i"
          :close-on-content-click="false"
          :open-on-hover="true"
          right
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-list-item
              v-bind="attrs"
              v-on="on"
              link
              ripple
            >
              <v-list-item-icon>
                <v-icon color="defaultIconColor">{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list>
            <v-list-item v-for="(x,i) in customActions" :key="i" @click="customActionAction(x)">
              <v-list-item-icon><v-icon color="defaultIconColor">{{ x.icon_key }}</v-icon></v-list-item-icon>
              <v-list-item-title>{{ x.action_title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu
          v-if="item.callMethod == 'moveAction' || item.callMethod == 'copyAction'"
          :key="i"
          :close-on-content-click="false"
          max-width="300"
          right
          offset-x
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-list-item
              v-bind="attrs"
              v-on="on"
              link
              ripple
            >
              <v-list-item-icon>
                <v-icon color="defaultIconColor">{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <NavigationModal
            :node="data"
            :submit-title="item.title"
            ref="navigationModal"
            @chosen-directory="callActionMethod(item, $event)"
          />
        </v-menu>

        <v-menu
          v-if="item.callMethod == 'copyAction'"
          :key="i+100"
          :close-on-content-click="false"
          :nudge-width="0"
          max-width="0"
          right
          offset-x
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-list-item
              v-bind="attrs"
              v-on="on"
              @click="confirmDuplicationModal()"
              link
              ripple
            >
              <v-list-item-icon>
                <v-icon color="defaultIconColor">mdi-content-duplicate</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('duplicate') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-menu>

        <v-list-item
          v-if="item.callMethod != 'moveAction' && item.callMethod != 'copyAction' && item.callMethod != 'customAction'"
          :key="i"
          :disabled="item.callMethod == 'downloadAction' && downloading"
          @click.stop="callActionMethod(item)"
          link
          ripple
        >
          <v-list-item-icon>
            <v-icon color="defaultIconColor">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>

    <DefaultModal
      :title="$t('duplicate_confirm_title')"
      ref="modalDuplicate"
      :submitButtonText="'duplicate'"
      @submit="duplicateAction()"
    >
      <template v-slot:content>
        {{$t('duplicate_confirm_body')}}
      </template>
    </DefaultModal>

    <DefaultModal
      :title="$t('remove')"
      ref="modalRemove"
      @submit="removeExecute"
      submit-button-text="remove"
      deleteModal
    >
      <template v-slot:content>
        {{ $t('remove_entry_confirmation') }} <b>{{ data.name }}</b> ?
      </template>
    </DefaultModal>

    <DefaultModal
      :title="$t('sync')"
      ref="modalSync"
      @submit="syncExecute"
      submit-button-text="sync"
      deleteModal
    >
      <template v-slot:content>
        {{ $t('sync_entry_confirmation') }} <b>{{ data.name }}</b> ?
      </template>
    </DefaultModal>

    <SecurityClassificationModal ref="classificationModal" />

    <GrantAccessModal ref="grantAccessModal" />

    <ConvertToGDocModal ref="convertToGDocsModal" />

    <ShareNetworkDriveModal ref="shareNetworkDriveModal" />

    <ShareModal ref="shareModal" />

    <RenameEntryModal ref="renameModal" />

    <TextModal ref="textModal" />

    <DefaultModal
      :title="$t(customActionTitle)"
      @submit="customActionSubmit"
      ref="customActionModal"
    >
      <template v-slot:content>
        <DynamicFormContent
          :fields="customActionFields"
        ></DynamicFormContent>
      </template>
    </DefaultModal>

  </v-card>
</template>

<style scoped>
  .card {
    min-width: 200px;
  }
</style>

<script>
import { mapState, mapActions } from 'vuex'
import router from '@/router'

import DefaultModal from '@/components/DefaultModal.vue'
import NavigationModal from '@/views/content/navigation/NavigationModal.vue'
import SecurityClassificationModal from '@/components/node/SecurityClassificationModal.vue'
import GrantAccessModal from '@/components/node/GrantAccessModal.vue'
import ConvertToGDocModal from '@/components/content/ConvertToGDocModal.vue'
import ShareNetworkDriveModal from '@/components/content/ShareNetworkDriveModal.vue'
import RenameEntryModal from '@/components/content/RenameEntryModal.vue'
import TextModal from '@/components/content/TextModal.vue'
import DynamicFormContent from '@/components/DynamicFormContent'
import ShareModal from '@/components/content/ShareModal.vue'
import AppActions from '@/store/app/actions-types'
import ContentActions from '@/store/content/actions/actions-types'
import EntryActions from '@/store/content/entry/actions-types'
import IconsByMimetype from '@/utils/iconsByMimetype.js'
import UpdateBrowserTab from '@/utils/updateBrowserTab'

export default {
  name: 'ContentActionsPopup',

  components: {
    DefaultModal,
    NavigationModal,
    SecurityClassificationModal,
    GrantAccessModal,
    ConvertToGDocModal,
    ShareNetworkDriveModal,
    RenameEntryModal,
    TextModal,
    DynamicFormContent,
    ShareModal
  },

  props: {
    data: {
      type: Object
    },
    filterType: {
      type: String
    },
  },

  data() {
    return {
      loading: true,
      actions: [],
      customActions: [],
      selectedCustomAction: {},
      customActionFields: [],
      downloading: false
    }
  },

  mounted() {
    this.fetchData()
  },

  computed: {
    ...mapState({
      nodeCardActions: state => state.content.actions.nodeCardActions,
    }),

    availableActions() {
      return this.nodeCardActions.filter((nca, ncaIdx, ncaArray) => {
          if(nca.group && !ncaArray.some(action => action.group == nca.group && action.divider)) {
            nca.divider = true
          }

          return this.actions.some(a => a.action_key == nca.key) &&
            nca.types.includes(this.filterType)
        }
      )
    },

    customActionTitle() {
      return this.selectedCustomAction?.action_title || ""
    },
  },

  methods: {
    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE,
      AppActions.OPEN_APP_ERROR_MESSAGE,
    ]),

    ...mapActions('content/actions', [
      ContentActions.GET_NODE_ACTIONS,
      ContentActions.DOWNLOAD_FILE,
      ContentActions.CUSTOM_ACTION,
      ContentActions.REMOVE,
      ContentActions.SYNC,
      ContentActions.MOVE,
      ContentActions.COPY,
    ]),

    ...mapActions('content/entry', [
      EntryActions.LIST_SUBFOLDER,
    ]),

    fetchData() {
      this.loading = true

      this[ContentActions.GET_NODE_ACTIONS](this.data.node_id)
        .then((result) => {
          var done = [];
          var noCustomAction = true;

          result.forEach(element => {
            if (element.pipeline_id === null) {
              this.actions.push(element);
            } else {
              if (noCustomAction) {
                this.actions.push({action_id: 0, action_key: 'custom_action'});

                noCustomAction = false;
              }

              if (done.includes(element.action_id) == false) {
                done.push(element.action_id);
                this.customActions.push(element);
              }
            }
          });

          this.loading = false
        })
        .catch(() => this.loading = false)
    },

    callActionMethod(action, event) {
      this[action.callMethod](event)
    },

    infoAction() {
      let routeData = router.resolve({name: 'EntryInfo',  params: { appId: this.$route.params.appId, nodeId: this.data.node_id }});
      let newWindow = window.open(routeData.href, '_blank');
      newWindow.addEventListener('load', () => {
        UpdateBrowserTab.update(newWindow, `${this.$t("information")} | ${this.data.name}`)
      });
    },

    customActionAction(actionData) {
      this.selectedCustomAction = {...actionData}

      let fields = []
      actionData?.routines.forEach(routine => {
        const routineFields = this.parseRoutineFormSpec(routine.routine_form_spec)?.fields
        if (!routineFields || routineFields.length === 0 ) {
          return
        }

        fields = [...fields, ...routineFields]
      })

      this.customActionFields = [...fields]

      if (! fields.length) {
        this.customActionSubmit(new FormData())

        return
      }

      this.$refs.customActionModal.openModal()
    },

    parseRoutineFormSpec(string) {
      try {
        return JSON.parse(string)
      } catch (e) {
        return
      }
    },

    customActionSubmit(formData) {
      const parentId = this.$route.params.parentId

      let parameters = {}
      for (const pair of formData.entries()) {
        parameters = {...parameters, ...{[pair[0]] : pair[1]}}
      }

      const data = {
        parentId: parentId,
        nodeId: this.data.node_id,
        name: this.data.name,
        pipelineId: this.selectedCustomAction.pipeline_id,
        routine: this.selectedCustomAction.action_title,
        parameters: { parameters }
      }

      this[ContentActions.CUSTOM_ACTION](data)
        .then(() => {
          this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('custom_action_successful'))
        })
        .finally(() => {
          this.$refs.customActionModal.closeModal()
        })
    },

    moveAction(destinationNodeId) {
      this.$refs.navigationModal.map((modal) => {modal.closeModal()})

      this[ContentActions.MOVE]({
          nodeId: this.data.node_id,
          params: {name: this.data.name, destination_id: destinationNodeId},
        })
        .then(() => {
          this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('move_successful'))
        }).finally(() => {
          this.$emit('move-success');
        })
    },

    copyAction(destinationNodeId) {
      this.$refs.navigationModal.map((modal) => {modal.closeModal()})

      this[ContentActions.COPY]({
          nodeId: this.data.node_id,
          params: {name: this.data.name, destination_id: destinationNodeId},
        })
        .then(() => {
          this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('copy_successful'))

          if (this.isAtDestinationPage(destinationNodeId)) {
            this[EntryActions.LIST_SUBFOLDER]({ parentId: destinationNodeId })
          }
        }).finally(() => {
          this.$emit('move-success')
        }).catch(() => {
          this[AppActions.OPEN_APP_ERROR_MESSAGE](this.$t('copy_error'))
        })
    },

    duplicateAction() {
      let parentId = this.$route.params.parentId ?? this.data.folder_parent_id ?? null

      this.$refs.modalDuplicate.closeModal()

      this[ContentActions.COPY]({nodeId: this.data.node_id, params: {name: this.data.name, destination_id: parentId}, parentId})
        .then(() => {
          this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('duplicate_successful'))

          if (this.isAtDestinationPage(parentId)) {
            this[EntryActions.LIST_SUBFOLDER]({ parentId })
          }
        })
    },

    isAtDestinationPage(destinationId) {
      return this.$route.matched.some(({ name }) => name == 'ContentNavigation' && this.$route.params.parentId == destinationId)
    },

    confirmDuplicationModal() {
      this.$refs.modalDuplicate.openModal()
    },

    renameAction() {
      this.$refs.renameModal.open(this.data)
    },

    removeAction() {
      this.$refs.modalRemove.openModal()
    },

    removeExecute() {
      this.$refs.modalRemove.closeModal()

      this[ContentActions.REMOVE]({nodeId: this.data.node_id, params: this.data})
        .then(() => {
          this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('remove_successful'))
        })
        .catch(() => {
          this.$refs.modalRemove.submitting = false
        })
    },

    syncAction() {
      this.$refs.modalSync.openModal()
    },

    syncExecute() {
      this[ContentActions.SYNC](this.data.node_id)
        .then((value) => {
          this.$refs.modalSync.closeModal()
          if(value.length == 0) value = [this.$t('no_changes')]
          this.$refs.textModal.open(value)
        })
        .catch(() => {
          this[AppActions.OPEN_APP_ERROR_MESSAGE](this.$t('sync_error'))
          this.$refs.modalSync.closeModal()
        })
    },

    downloadAction() {
      this.downloading = true
      this[ContentActions.DOWNLOAD_FILE]({nodeId: this.data.node_id, control: this})
    },

    attachActionsetsAction() {
      router.push({
        name: 'AttachActionsets',
        params: { appId: this.$route.params.appId, nodeId: this.data.node_id }
      })
    },

    attachEnablerAction() {
      router.push({
        name: 'AttachEnablers',
        params: { appId: this.$route.params.appId, nodeId: this.data.node_id }
      })
    },

    securityClassificationAction() {
      this.$refs.classificationModal.open(this.data.node_id, this.data.name)
    },

    attachSecurityGroupsAction() {
      router.push({
        name: 'AttachSecurityGroups',
        params: { appId: this.$route.params.appId, nodeId: this.data.node_id }
      })
    },

    grantAccessAction() {
      this.$refs.grantAccessModal.open(this.data)
    },

    editGDocAction() {
      let routeData = router.resolve({name: 'EditGDoc', params: { appId: this.$route.params.appId, nodeId: this.data.node_id }, query: { from: this.$route.fullPath } });
      let newWindow = window.open(routeData.href, '_blank');
      let icon = IconsByMimetype.getIconByMimetype(this.data.extension, this.data.mime).icon
      newWindow.addEventListener('load', () => {
        UpdateBrowserTab.update(newWindow, this.data.name, icon)
      });
    },

    convertToGDocsAction() {
      this.$refs.convertToGDocsModal.open(this.data.node_id, this.data.mime)
    },

    editJstAction() {
      let routeData = router.resolve({name: 'EditJst', params: { appId: this.$route.params.appId, nodeId: this.data.node_id }, query: { from: this.$route.fullPath } });
      let newWindow = window.open(routeData.href, '_blank');
      newWindow.addEventListener('load', () => {
        UpdateBrowserTab.update(newWindow, this.data.name)
      });
    },

    shareNetworkDriveAction() {
      this.$refs.shareNetworkDriveModal.open(this.data)
    },

    shareModalAction() {
      this.$refs.shareModal.open(this.data)
    },

    previewAction() {
      this.$emit('preview')
    },

    editSyntheticMediaAction() {
      let routeData = router.resolve({name: 'EditSyntheticMedia',  params: { appId: this.$route.params.appId, nodeId: this.data.node_id }});
      let newWindow = window.open(routeData.href, '_blank');
      newWindow.addEventListener('load', () => {
        UpdateBrowserTab.update(newWindow, this.data.name, 'mdi-star-four-points-box')
      });
    },

  },
}

</script>
