<template>

  <DefaultCrudView
    @create-new="newAttributeMapping"
    :breadcrumbsItems="breadcrumbs"
    :actions="actions"
  >
    <template slot="content">

      <v-data-table
        :headers="headers"
        :items="attributeMappings"
        class="elevation-1"
        :loading="loading"
        :options.sync="options"
        :server-items-length="itemsLength"
        :footer-props="$defaultDataTableFooterProps"
      >

        <template v-slot:item.tenant_id="{ item }">
          <span
            color="primary"
            dark
          >
            {{ getTenantTitle(item.tenant_id) }}
          </span>
        </template>

        <template v-slot:item.customer_properties="{ item }">
          <span
            color="primary"
            dark
          >
            {{ getCustomerPropertiesTitles(item.customer_properties) }}
          </span>
        </template>
      
        <template v-slot:item.actions="{ item }">

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click="editAttributeMappingModal(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>{{ $t('edit') }}</span>
          </v-tooltip>

          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click="deleteAttributeMappingModal(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>{{ $t('delete') }}</span>
          </v-tooltip>

        </template>

      </v-data-table>

      <DefaultModal :title="getModalTitle()" ref="modal" v-on:submit="save">
        <template v-slot:content>
          <DynamicFormContent 
            :fields="fields"
            @tenant-change="handleSelectedTenant"
          ></DynamicFormContent>
        </template>
      </DefaultModal>

      <DefaultModal
        :title="$t('Delete Attribute Mapping')"
        ref="deleteModal"
        @submit="remove"
        submit-button-text="delete"
        deleteModal
      >
        <template v-slot:content>
          {{ $t('remove_attribute_mapping') }} <b>{{ selectedAttributeMapping ? selectedAttributeMapping.customer_property_attribute : '' }}</b> ?
        </template>
      </DefaultModal>

    </template>
  
  </DefaultCrudView>
</template>

<style scoped>
  .container {
    padding: 0;
  }

  .table-container {
    margin-top: 20px;
  }

  >>>.inactive-row {
    color: #616161;
    opacity: 0.6;
  }

</style>

<script>

import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'

import DefaultCrudView from '@/components/DefaultCrudView.vue'
import DefaultModal from '@/components/DefaultModal.vue'
import DynamicFormContent from '@/components/DynamicFormContent.vue'

import TenantActions from '@/store/core/tenant/actions-types'
import CustomerPropertyActions from '@/store/cdp/customer_property/actions-types'
import AttributeMappingActions from '@/store/cdp/attribute_mapping/actions-types'
import AttributeMappingMutations from '@/store/cdp/attribute_mapping/mutations-types'
import AppActions from '@/store/app/actions-types'

export default {
  name: "AttributeMapping",

  components: {
    DefaultCrudView,
    DefaultModal,
    DynamicFormContent
  },

  computed: {
    ...mapGetters('cdp/customer_property', [
      'customerPropertyTitleById'
    ]),

    ...mapState({
      fields: state => state.cdp.attribute_mapping.fields,
      attributeMappings: state => state.cdp.attribute_mapping.attributeMappings,
      selectedAttributeMapping: state => state.cdp.attribute_mapping.selectedAttributeMapping,
    }),

    breadcrumbs() {
      return [
        {
          text: this.$t('cdp_settings'),
          prependIcon: 'mdi-cog-outline',
          iconColor: 'primary',
          to: {
            name: "CdpSettings",
            params: { appId: this.$route.params.appId }
          },
          disabled: false,
          exact: true,
          ripple: true,
        },
        {
          text: this.$t('attribute_mapping'),
        },
      ]
    }
  },

  data() {
    return {
      loading: true,
      options: {},
      itemsLength: 0,
      tenants: [],
      headers: [
        {text: 'Customer property attribute', value: 'customer_property_attribute'},
        {text: 'Media attribute', value: 'media_attribute'},
        {text: 'Media', value: 'media'},
        {text: 'Tenant', value: 'tenant_id'},
        {text: 'Customer Properties', value: 'customer_properties'},
        {text: 'Actions', value: 'actions', sortable: false},
      ],
      actions: [
        {
          text: "create_new",
          eventToEmit: "create-new",
        }
      ],
    }
  },

  mounted() {
    this.loading = true
    this.[AttributeMappingMutations.SET_SELECTED_ATTRIBUTE_MAPPING](null)

    this.[TenantActions.GET_ALL_TENANTS]()
      .then((tenants) => {
        this.[AttributeMappingMutations.SET_FIELD_TENANTS](tenants.items)

        this.tenants = [...tenants.items]

        this.[CustomerPropertyActions.GET_ALL]()
        .then(() => {
          this.reloadData()
        })
      })
  },

  watch: {
    options: {
      handler () {
        this.reloadData()
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE
    ]),

    ...mapActions('core/tenant', [
      TenantActions.GET_ALL_TENANTS
    ]),

    ...mapActions('cdp/customer_property', [
      CustomerPropertyActions.GET_ALL,
      CustomerPropertyActions.GET_CUSTOMER_PROPERTIES_BY_TENANT,
    ]),

    ...mapActions('cdp/attribute_mapping', [
      AttributeMappingActions.GET_ALL,
      AttributeMappingActions.SAVE_ATTRIBUTE_MAPPING,
      AttributeMappingActions.DELETE_ATTRIBUTE_MAPPING,
    ]),

    ...mapMutations('cdp/attribute_mapping', [
      AttributeMappingMutations.SET_SELECTED_ATTRIBUTE_MAPPING,
      AttributeMappingMutations.SET_FIELD_TENANTS,
      AttributeMappingMutations.SET_FIELD_CUSTOMER_PROPERTIES,
    ]),

    reloadData() {
      let options = {...this.$route.query}

      if (this.options.sortBy.length > 0) {
        options.order = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0 && this.options.sortDesc[0]) {
        options.direction = 'DESC'
      }

      options.limit = this.options.itemsPerPage
      options.offset = this.options.itemsPerPage * (this.options.page - 1)

      this.loading = true

      this.[AttributeMappingActions.GET_ALL](options)
        .then((result) => {
          this.itemsLength = result.total

          this.loading = false
        })
        .catch(() => this.loading = false)
    },

    newAttributeMapping() {
      this.[AttributeMappingMutations.SET_SELECTED_ATTRIBUTE_MAPPING](null)
      this.$refs.modal.openModal()
    },

    editAttributeMappingModal(attributeMapping) {
      this.[AttributeMappingMutations.SET_SELECTED_ATTRIBUTE_MAPPING](attributeMapping)

      this.handleSelectedTenant(attributeMapping.tenant_id, false)

      this.$refs.modal.openModal()
    },

    save() {
      this.[AttributeMappingActions.SAVE_ATTRIBUTE_MAPPING]()
        .then(() => {
          let message = this.selectedAttributeMapping ? 'Attribute Mapping edited' : 'Attribute Mapping created'

          this.[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t(message))

          this.$refs.modal.closeModal()

          this.reloadData()
        })
        .catch(() => this.$refs.modal.submitting = false)
    },

    deleteAttributeMappingModal(attributeMapping) {
      this.[AttributeMappingMutations.SET_SELECTED_ATTRIBUTE_MAPPING](attributeMapping)

      this.$refs.deleteModal.openModal()
    },

    remove() {
      this.[AttributeMappingActions.DELETE_ATTRIBUTE_MAPPING]()
        .then(() => {
          this.[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('attribute_mapping_deleted'))

          this.$refs.deleteModal.closeModal()

          this.reloadData()
        })
        .catch(() => this.$refs.deleteModal.submitting = false)
    },

    getModalTitle() {
      return this.selectedAttributeMapping ? this.$t('Edit') : this.$t('new_attribute_mapping')
    },

    getTenantTitle(id) {
      let tenant = this.tenants.find(f => f.tenant_id == id)

      return tenant ? tenant.title : ''
    },

    getCustomerPropertiesTitles(customerProperties) {
      let titles = []

      customerProperties.forEach((item) => titles.push(item.customer_property_title))

      return titles.join(', ')
    },

    handleSelectedTenant(tenantId, clearValue = true) {
      this.$refs.modal.submitting = true

      this.[CustomerPropertyActions.GET_CUSTOMER_PROPERTIES_BY_TENANT]({tenantId})
        .then((customerProperties) => {

          this.[AttributeMappingMutations.SET_FIELD_CUSTOMER_PROPERTIES]({
            customerProperties: customerProperties.items,
            clearValue,
          })
          
          this.$refs.modal.submitting = false
        })
        .catch(() => this.$refs.modal.submitting = false)
    },
  }
}

</script>