<template>
  <div class="video-preview-container">
    <video
      :src="singlePagePreviewUrl"
      ref="player"
      class="player"
      controlsList="nodownload"
      controls
    ></video>
  </div>
</template>

<style scoped>
  .video-preview-container {
    display: contents;
  }

  .player {
    max-height: 900px;
    max-width: 900px;
  }
</style>

<script>

export default {
  name: 'VideoPreview',

  props: {
    previewsUrls: {
      type: Array,
      required: true,
    },

    modal: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    modal() {
      if (false === this.modal) {
        this.onModalClose()
      }
    }
  },

  computed: {
    singlePagePreviewUrl() {
      return this.previewsUrls[0]
    },
  },

  methods: {
    onModalClose() {
      this.$refs.player.pause()
    },
  }
}

</script>
