var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DefaultCrudView',{attrs:{"breadcrumbsItems":_vm.breadcrumbs,"actions":_vm.actions},on:{"create-new":_vm.newAttributeMapping}},[_c('template',{slot:"content"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.attributeMappings,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.itemsLength,"footer-props":_vm.$defaultDataTableFooterProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.tenant_id",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"color":"primary","dark":""}},[_vm._v(" "+_vm._s(_vm.getTenantTitle(item.tenant_id))+" ")])]}},{key:"item.customer_properties",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"color":"primary","dark":""}},[_vm._v(" "+_vm._s(_vm.getCustomerPropertiesTitles(item.customer_properties))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){return _vm.editAttributeMappingModal(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('edit')))])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){return _vm.deleteAttributeMappingModal(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('delete')))])])]}}])}),_c('DefaultModal',{ref:"modal",attrs:{"title":_vm.getModalTitle()},on:{"submit":_vm.save},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('DynamicFormContent',{attrs:{"fields":_vm.fields},on:{"tenant-change":_vm.handleSelectedTenant}})]},proxy:true}])}),_c('DefaultModal',{ref:"deleteModal",attrs:{"title":_vm.$t('Delete Attribute Mapping'),"submit-button-text":"delete","deleteModal":""},on:{"submit":_vm.remove},scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('remove_attribute_mapping'))+" "),_c('b',[_vm._v(_vm._s(_vm.selectedAttributeMapping ? _vm.selectedAttributeMapping.customer_property_attribute : ''))]),_vm._v(" ? ")]},proxy:true}])})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }