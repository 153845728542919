<template>
  <DefaultModal
    :title="$t('rename')"
    @submit="renameExecute"
    ref="modal"
  >
    <template v-slot:content>
      <v-text-field
        :label="data.name"
        v-model="fieldName"
        :autofocus="true"
        :rules="[
          v => !!v || $t('form_rules.mandatory'),
          v => /^([a-zA-Z0-9À-ž\s\.,_-]+)$/igu.test(v) || $t('form_rules.invalid_filename'),
          v => !/^(PRN|CON|AUX|CLOCK$|NUL|COM[0-9]|LPT[0-9])$/im.test(v) || $t('form_rules.invalid_filename'),
        ]"
        name="name"
        outlined
        dense
      ></v-text-field>
    </template>
  </DefaultModal>
</template>

<script>
import { mapActions } from 'vuex'

import DefaultModal from '@/components/DefaultModal.vue'

import AppActions from '@/store/app/actions-types'
import ContentActions from '@/store/content/actions/actions-types'

export default {
  name: 'RenameEntryModal',

  components: {
    DefaultModal,
  },

  data() {
    return {
      data: {},
      fieldName: "",
    }
  },

  methods: {
    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE,
    ]),

    ...mapActions('content/actions', [
      ContentActions.RENAME,
    ]),

    open(data) {
      this.data = {...data}
      this.fieldName = data.name

      this.$refs.modal.submitting = false

      this.$refs.modal.openModal()
    },

    renameExecute(formData) {
      const name = formData.get('name')

      this[ContentActions.RENAME]({nodeId: this.data.node_id, params: {name}})
        .then(() => {
          this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('rename_successful'))

          this.$refs.modal.closeModal()
        })
        .catch(() => {
          this.$refs.modal.submitting = false
        })
    },
  },
}

</script>