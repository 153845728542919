<template>
    <DefaultModal
      :title="$t('sync_change_result')"
      :hideSaveButton="true"
      ref="modal"
    >
      <template v-slot:content>
        <v-list dense>
            <v-list-item
                v-for="item in items"
                :key="item.key"
                :title="item.title"
            >
                <v-list-item-content>
                    <v-list-item-title style="white-space: normal;">
                        {{ item.title }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
      </template>
    </DefaultModal>
  </template>
  
  <script>
  import DefaultModal from '@/components/DefaultModal.vue'
  
  export default {
    name: 'TextModal',
  
    components: {
      DefaultModal,
    },
  
    data: () => ({
      items: [],
    }),
  
    methods: {
      open(value) {
        this.items =  [];
        let i = 0;
        while (i < value.length) {
            this.items.push({ title: value[i], key: i });
            i++;
        }
        this.$refs.modal.submitting = false
        this.$refs.modal.openModal()
      },
    },
  }
  
  </script>